import React from 'react';
import Image from 'next/image';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import SectionHeading from '../Common/SectionHeading';
import Link from 'next/link';
import {
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"
    }
}));
const WhyPharmdelve = ({title=null}) => {
  return (
    <>
    <div id="WhyPharmdelve">
        <div className="flex flex-col justify-center w-11/12 mx-auto my-6 xl:max-w-screen max-w-screen-2xl lg:my-10">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <div className='relative w-full h-auto'>
                    <div className="rare-box"></div>
                    <Image
                        priority={true}
                        alt="Why Phamdelve"
                        src={process.env.IMG_CDN+'img/why-pharmdelve-gif.gif'}
                        width={2000}
                        height={2000}
                        className='w-full mx-auto imageAbout'
                    />
                    <>
                        <div className='flex justify-between'>
                            <p className='m-0'>Ready Reports </p>
                            <p className='m-0'> 580+ </p>
                        </div>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ position: "relative" }}></Box>
                            <BorderLinearProgress variant="determinate" value={80} />
                        </Box>
                        <br/>
                    </>
                    <>
                        <div className='flex justify-between'>
                            <p className='m-0'>Upcoming Reports </p>
                            <p className='m-0'> 120+ </p>
                        </div>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ position: "relative" }}></Box>
                            <BorderLinearProgress variant="determinate" value={30} />
                        </Box>
                        <br/>
                    </>
                    <>
                        <div className='flex justify-between'>
                            <p className='m-0'>On-Demand Reports </p>
                            <p className='m-0'> 550+ </p>
                        </div>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ position: "relative" }}></Box>
                            <BorderLinearProgress variant="determinate" value={70} />
                        </Box>
                    </>
                </div>
                <div className='relative w-full h-auto p-2 md:p-6'>
                    <p className='mb-0 text-left'>Why PharmDelve?</p>
                    {(title) &&
                        <SectionHeading title={title}  customClass="text-left"/>
                    }
                    <p>PharmDelve provides the right technology to clients, allowing them to optimize their business goals and make time-critical decisions. It is committed to transforming data into comprehensive insights using human and machine intelligence. We distinguish ourselves with trusted CAGRs, 11-year forecasts, unrivaled coverage, KOL opinions, and a dedicated team. PharmDelve has covered everything, from existing drugs to emerging ones, from rare diseases to the most common. Committed to providing the best insights to help clients gain an advantage.</p>
                    <ul>
                        <li className="flex text-base">
                            <div className="pr-4">
                                <FontAwesomeIcon style={{ color: '#1b5df3', marginRight: '5px' }} icon={faCheck}/>
                            </div>
                            Our intelligence solutions are tailored to users in various industries around the world
                        </li>
                        <li className="flex text-base">
                            <div className="pr-4">
                                <FontAwesomeIcon style={{ color: '#1b5df3', marginRight: '5px' }} icon={faCheck}/>
                            </div>
                            We fully integrate our proprietary data and cutting-edge solutions into a single platform
                        </li>
                    </ul>
                    <button className='btn mt-7'>
                    <Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}}>Get Started</Link></button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
export default WhyPharmdelve;
